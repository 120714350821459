<script setup>
import { onMounted, ref } from 'vue';
import useWarehouses from '@/hooks/useWarehouses';
import useClients from '@/hooks/useClients';
import useSpecialOrders from '@/hooks/useSpecialOrders';
import { useToast } from "vue-toastification";

const { clients, fetchClients } = useClients();
const { warehouses, fetchWarehouses } = useWarehouses();
const { addSpecialOrder } = useSpecialOrders();
const toast = useToast()

const clientUrlParams = {
    filter: {
        name: {
            type: "contains",
            filter: "",
        },
    },
    sort: [
        {
            sort: "asc",
            colId: "name",
        },
    ],
    limit: 500,
};

onMounted(async () => {
    await fetchClients(clientUrlParams);
    await fetchWarehouses();
});

// order details
const special_order = ref({
    client_id: null,
    warehouse_id: null,
    po_number: null,
    week: null,
    sos_number: null,
    client_order_number: null,
    client_customer_number: null,
    origin_name: null,
    customer_number: null,
    customer_name: null,
    items: []
})

const addItem = () => {
    special_order.value.items.push({})
}

const validateFields = () => {

    if (special_order.value.client_id == null) {
        throw new Error("Client is required.");
    }

    if (special_order.value.warehouse_id == null) {
        throw new Error("Warehouse is required.");
    }

    if (special_order.value.week == null) {
        throw new Error("Week is required.");
    }

    if (special_order.value.po_number == null) {
        throw new Error("PO Number is required.");
    }
}

const handleSubmit = () => {
    try {
        validateFields();
        addSpecialOrder(special_order.value);
    } catch (error) {
        toast.error(error.message);
    }
}

</script>

<template>
    <v-card class="ma-2">
        <v-card-title class="bg-surface-dark">Special Orders // New</v-card-title>
        <v-row class="mt-4">
            <v-col cols="6" md="6">
                <v-card-text>
                    <v-autocomplete v-model="special_order.client_id" density="compact" :items="clients"
                        @update:search="(query) => debouncedFetchClients(query)" item-title="name" item-value="id"
                        hide-details variant="outlined">
                        <template v-slot:label>
                            Client <span class="text-red">*</span>
                        </template>
                    </v-autocomplete>
                </v-card-text>
            </v-col>
            <v-col cols="6" md="6">
                <v-card-text>
                    <v-autocomplete v-model="special_order.warehouse_id" density="compact" :items="warehouses?.data"
                        item-title="name" item-value="id" hide-details variant="outlined">
                        <template v-slot:label>
                            Warehouse <span class="text-red">*</span>
                        </template>
                    </v-autocomplete>
                </v-card-text>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-col cols="6" md="6">
                <v-card-text>
                    <v-text-field v-model="special_order.po_number" density="compact" variant="outlined" hide-details
                        placeholder="Enter your po number...">
                        <template v-slot:label>
                            PO Number <span class="text-red">*</span>
                        </template>
                    </v-text-field>
                </v-card-text>
            </v-col>
            <v-col cols="6" md="6">
                <v-card-text>
                    <v-text-field v-model="special_order.week" density="compact" variant="outlined" hide-details
                        placeholder="Enter your week...">
                        <template v-slot:label>
                            Week <span class="text-red">*</span>
                        </template>
                    </v-text-field>
                </v-card-text>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-col cols="6" md="6">
                <v-card-text>
                    <v-text-field v-model="special_order.sos_number" density="compact" variant="outlined" hide-details
                        placeholder="Enter your SOS number..." label="SOS Number">
                    </v-text-field>
                </v-card-text>
            </v-col>
            <v-col cols="6" md="6">
                <v-card-text>
                    <v-text-field v-model="special_order.client_order_number" density="compact" variant="outlined"
                        hide-details placeholder="Enter your Client Order Number..." label="Client Order Number">
                    </v-text-field>
                </v-card-text>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-col cols="6" md="6">
                <v-card-text>
                    <v-text-field v-model="special_order.client_customer_number" density="compact" variant="outlined"
                        hide-details placeholder="Enter your client customer number..." label="Client Customer Number">
                    </v-text-field>
                </v-card-text>
            </v-col>
            <v-col cols="6" md="6">
                <v-card-text>
                    <v-text-field v-model="special_order.origin_name" density="compact" variant="outlined" hide-details
                        placeholder="Enter your origin name..." label="Origin Name">
                    </v-text-field>
                </v-card-text>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-col cols="6" md="6">
                <v-card-text>
                    <v-text-field v-model="special_order.customer_number" density="compact" variant="outlined"
                        hide-details placeholder="Enter your customer number..." label="Customer Number">
                    </v-text-field>
                </v-card-text>
            </v-col>
            <v-col cols="6" md="6">
                <v-card-text>
                    <v-text-field v-model="special_order.customer_name" density="compact" variant="outlined"
                        hide-details label="Customer Name" placeholder="Enter your customer name...">
                    </v-text-field>
                </v-card-text>
            </v-col>
        </v-row>
    </v-card>

    <div>
        <v-card class="ma-2 mt-6">
            <v-card-title class="bg-surface-dark">Line Items</v-card-title>
            <v-row class="mb-2 ml-6 mt-1" v-if="special_order.items.length > 0">
                <v-col cols="3">
                    <strong>SKU</strong>
                </v-col>
                <v-col cols="3">
                    <strong>Description</strong>
                </v-col>
                <v-col cols="3">
                    <strong>Case Quantity</strong>
                </v-col>
                <v-col cols="3">
                    <strong>Bottle Quantity</strong>
                </v-col>
            </v-row>

            <v-row v-for="(item, index) in special_order.items" :key="index" class="mb-4 ml-2 mr-2">
                <v-col cols="3">
                    <v-text-field hide-details density="compact" variant="outlined" v-model="item.sku" />
                </v-col>
                <v-col cols="3">
                    <v-text-field hide-details density="compact" variant="outlined" v-model="item.description" />
                </v-col>
                <v-col cols="3">
                    <v-text-field hide-details density="compact" variant="outlined" v-model="item.case_qty" />
                </v-col>
                <v-col cols="3">
                    <v-text-field hide-details density="compact" variant="outlined" v-model="item.bottle_qty" />
                </v-col>
            </v-row>
            <div class="text-center mb-4 mt-6">
                <v-btn @click="addItem">+Add Item</v-btn>
            </div>
        </v-card>
        <v-btn class="float-right mt-4 mr-2" color="primary" @click="handleSubmit">Submit SLO</v-btn>
    </div>
</template>